<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="12" class="text-left mt-1">
          <b-row>
            <b-col md="12"><h4>Content Data</h4></b-col>
            <b-col md="12">
              <div>
                <!-- types -->
                <app-collapse accordion :type="collapseType">
                  <app-collapse-item title="Header">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="6">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.header.title }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Subtitle</b></p>
                            <p>{{ model.header.subtitle }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col
                        md="2"
                        v-for="(i, index) in model.trustedBrands.brands"
                        :key="index"
                      >
                        <b-media
                          class="d-flex justify-content-center mb-0 flex-wrap ml-2"
                          no-body
                        >
                          <b-media-aside>
                            <b-avatar
                              :src="i"
                              class="mb-1"
                              size="90px"
                            ></b-avatar>
                          </b-media-aside>
                        </b-media>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Companies Description">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12">
                            <b-row>
                              <b-col md="4">
                                <b-img
                                  rounded
                                  :src="model.description.image"
                                  alt="Rounded image"
                                  class="d-inline-block mr-1 mb-1"
                                  width="100px"
                                />
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Title</b></p>
                                <p>{{ model.description.title }}</p>
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Description</b></p>
                                <p>{{ model.description.description }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Feature">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12">
                            <b-row
                              v-for="(i, index) in model.features"
                              :key="index"
                            >
                              <b-col md="4">
                                <b-img
                                  rounded
                                  :src="i.logo"
                                  alt="Rounded image"
                                  class="d-inline-block mr-1 mb-1"
                                  width="100px"
                                />
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Title</b></p>
                                <p>{{ i.title }}</p>
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Subitle</b></p>
                                <p>{{ i.subtitle }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Team">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row v-for="(i, index) in model.teams" :key="index">
                          <b-col md="2">
                            <b-media
                              class="d-flex justify-content-center mb-0 flex-wrap ml-2"
                              no-body
                            >
                              <b-media-aside>
                                <b-avatar
                                  :src="i.avatar"
                                  class="mb-1"
                                  size="90px"
                                ></b-avatar>
                              </b-media-aside>
                            </b-media>
                          </b-col>
                          <b-col md="10">
                            <b-row>
                              <b-col md="4">
                                <p class="mb-0"><b>Name</b></p>
                                <p>{{ i.name }}</p>
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Position</b></p>
                                <p>{{ i.position }}</p>
                              </b-col>

                              <b-col md="4">
                                <p class="mb-0"><b>Description</b></p>
                                <p>{{ i.description }}</p>
                              </b-col>
                            </b-row>

                            <b-row v-for="(x, y) in i.social" :key="y">
                              <b-col md="6">
                                <p class="mb-0"><b>Type</b></p>
                                <p>{{ x.type }}</p>
                              </b-col>
                              <b-col md="6">
                                <p class="mb-0"><b>Url</b></p>
                                <p>{{ x.url }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Our Companies">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row
                          v-for="(i, index) in model.companies"
                          :key="index"
                        >
                          <b-col md="2">
                            <b-media
                              class="d-flex justify-content-center mb-0 flex-wrap ml-2"
                              no-body
                            >
                              <b-media-aside>
                                <b-avatar
                                  :src="i.logo"
                                  class="mb-1"
                                  size="90px"
                                ></b-avatar>
                              </b-media-aside>
                            </b-media>
                          </b-col>
                          <b-col md="10">
                            <b-row class="mt-2">
                              <b-col md="4">
                                <p class="mb-0"><b>Title</b></p>
                                <p>{{ i.title }}</p>
                              </b-col>
                              <b-col md="4">
                                <p class="mb-0"><b>Description</b></p>
                                <p>{{ i.description }}</p>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Trusted Brand">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="12" class="mb-1">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.trustedBrands.title }}</p>
                          </b-col>
                          <b-col
                            md="2"
                            v-for="(i, index) in model.trustedBrands.brands"
                            :key="index"
                          >
                            <b-media
                              class="d-flex justify-content-center mb-0 flex-wrap ml-2"
                              no-body
                            >
                              <b-media-aside>
                                <b-avatar
                                  :src="i"
                                  class="mb-1"
                                  size="90px"
                                ></b-avatar>
                              </b-media-aside>
                            </b-media>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>

                  <app-collapse-item title="Contact Us">
                    <b-row>
                      <b-col md="12" class="mt-1">
                        <b-row>
                          <b-col md="6">
                            <p class="mb-0"><b>Title</b></p>
                            <p>{{ model.contactUs.title }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Subtitle</b></p>
                            <p>{{ model.contactUs.subtitle }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Email</b></p>
                            <p>{{ model.contactUs.email }}</p>
                          </b-col>

                          <b-col md="6">
                            <p class="mb-0"><b>Phone</b></p>
                            <p>{{ model.contactUs.phoneNumber }}</p>
                          </b-col>

                          <b-col md="12">
                            <p class="mb-0"><b>Address</b></p>
                            <p>{{ model.contactUs.address }}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'content-about-us' }"
      >
        Back
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BFormRadio,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    vSelect,
    BTab,
    BTabs,
    BFormRadio,
    BModal,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      collapseType: "margin",
      config: {
        api: "content/about-us",
      },
      model: {},
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data;
      });
    },
  },
};
</script>
